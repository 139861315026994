import React from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";

import styles from "./contact.module.css";
import Layout from "../components/Layout";

import { emailRgx, encode } from "../utils/formUtils";

function Contact() {
  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });

  const onSubmit = async (data) => {
    console.log(data);
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...data }),
      });

      navigate("/success", {
        state: { data },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <Layout location="Contact">
      <h1>Kontaktirajte nas</h1>
      <p></p>

      <form
        className={styles.form}
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />

        <div className={styles.formGrid}>
          <label className={styles.email}>
            Email
            <input
              type="text"
              name="email"
              id="email"
              ref={register({
                required: "Ovo polje je obavezno",
                pattern: { value: emailRgx, message: "Invalid email" },
              })}
            />
            {errors.email && (
              <p className={styles.errorMsg}>{errors.email.message}</p>
            )}
          </label>

          <label className={styles.name}>
            Ime i prezime (neobavezno)
            <input type="text" name="name" id="name" ref={register} />
          </label>
          <label className={styles.phone}>
            Broj telefona (neobavezno)
            <input type="tel" name="phone" id="phone" ref={register} />
            <p>Za lakše kontaktiranje</p>
          </label>

          <label className={styles.message}>
            Poruka
            <textarea
              id="message"
              name="message"
              ref={register({
                required: "Ovo polje je obavezno",
              })}
            />
            {errors.message && (
              <p className={styles.errorMsg}>{errors.message.message}</p>
            )}
          </label>
        </div>

        <button type="submit">Pošalji upit</button>
      </form>
    </Layout>
  );
}

export default Contact;
